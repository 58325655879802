@import '~assets/sass/variables';

$feildHeight: 30px;
$inputErrorColor: #a54855;
$inputErrorField: #a54855;
$borderColor: #999999;

.form-section {
  margin-bottom: 15px;
}

.form-engine-group {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  &.disabled {
    > * {
      opacity: 0.6;
      cursor: not-allowed !important;
    }
  }

  &.short-field {
    input,
    select,
    ngx-select {
      width: calc( 50% - 3px );
      // margin-right: 5px;
    }
  }

  &.two-field {
    .fields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      > .item-field {
        flex-grow: 1;
        width: 100%;

        &:first-child {
          margin-right: 5px;
        }

        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }

  &.has-error {
    > label {
      color: $inputErrorColor;

      > span {
        font-weight: normal;
      }
    }

    input,
    .pg-select > .pg-select-selection ,
    .ant-calendar-picker > span > input,
    textarea,
    .form-radio-button > .form-radio label {
      border: 1.2px solid $inputErrorField !important;
      // background: rgba($inputErrorField, 0.05);
    }
  }

  > .error {
    font-size: 12px;
    color: $inputErrorColor;
    margin: 0;
    margin-top: 5px;
    white-space: nowrap;
  }

  > label {
    font-family: $robotoRegular;
    font-weight: bold;
    font-size: 12px;
    color: #666666;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    height: $feildHeight;
    background: #ffffff;
    border: 1px solid $borderColor;
    border-radius: 3px;
    font-family: $robotoRegular;
    font-size: 12px;
    color: #666666;
    padding: 0 10px;

    &:focus,
    &:active {
      border: 1px solid $borderColor;
    }

    &:focus:not(:focus-visible) { outline: none }
    &:focus-visible {
      outline: none;
    }
  }

  select {
    width: 100%;
    height: $feildHeight;
    background: #ffffff;
    border: 1px solid $borderColor;
    color: #666666;
    border-radius: 3px;
    font-family: $robotoRegular;
    font-size: 12px;
    padding: 0 5px;

    &:focus,
    &:active {
      border: 1px solid $borderColor;
    }
  }

  &.engine-feild {
    ngx-select {
      > .ngx-select {
        > .ngx-select__selected > .ngx-select__toggle {
          background: transparent;
          border: 1px solid #e6e6e6;
          border-radius: 8px;
        }
      }
    }
  }

  ngx-select {
    > .ngx-select {
      > .ngx-select__selected > .ngx-select__toggle {
        height: $feildHeight;
        background: #ffffff;
        border: 1px solid $borderColor;
        color: #666666;
        border-radius: 3px;
        font-family: $robotoRegular;
        font-size: 12px;

        > .ngx-select__toggle-buttons {
          align-items: unset;
        }
      }

      > .ngx-select__choices > .ngx-select__item-group {
        > .ngx-select__item {
          font-family: $robotoRegular;
          font-size: 12px;
          padding: 3px 15px;
        }
      }
    }
  }

  .form-radio-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .form-radio {
      position: relative;
      height: $feildHeight;
      flex-grow: 1;

      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 5px;
      }

      label {
        cursor: pointer;
        width: 100%;
        height: 100%;
        font-family: $robotoRegular;
        font-size: 12px;
        color: #666666;
        text-align: center;
        line-height: 18px;
        border: 1px solid $borderColor;
        border-radius: 4px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      input[type='radio'] {
        display: none;

        &:checked + label {
          background: #C1E6E4;
          border: 1px solid #4DA199;
          color: #588f8a;
        }
      }
    }
  }
}

.checkbox-engine {
  > label {
    cursor: pointer;
    position: relative;
    font-family: $robotoMedium;
    font-weight: 500 !important;
    font-size: 14px;
    padding-left: 20px;
    color: #cccccc;
    margin: 0;

    &:after {
      content: '';
      display: table;
      clear: both;
    }

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"]+.cr>.cr-icon {
      opacity: 0;
    }

    input[type="checkbox"]:checked+.cr>.cr-icon {
      opacity: 1;
    }

    input[type="checkbox"]:checked ~ .lbl-text {
      color: #fafafa;
    }

    input[type="checkbox"]:checked ~ .cr {
      color: #fafafa;
      border-color: #cccccc;
    }

    .checkbox label input[type="checkbox"]:disabled+.cr {
      opacity: .5;
    }

    .cr {
      // position: relative;
      display: inline-block;
      border: 1px solid #cccccc;
      border-radius: .25em;
      width: 14px;
      height: 14px;
      position: absolute;
      left: 0;
      top: 2px;
  
      > .cr-icon {
        position: absolute;
        font-size: 13px;
        line-height: 0;
        top: 6px;
        left: 1px;
      }
    }
  }
}

.radio-btn {
  cursor: pointer;
  position: relative;
  font-family: $robotoMedium;
  font-weight: 500 !important;
  font-size: 14px;
  color: #cccccc;
  margin: 0;
  margin-bottom: 2px;
  display: block;
  padding-left: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  // input:checked ~ .checkmark {
  //   background-color: #2196F3;
  // }

  input:checked ~ .lbl-text {
    color: #fafafa;
  }

  input:checked ~ .checkmark {
    border-color: #fafafa;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  input:checked ~ .checkmark:after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fafafa;
  }
  
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 14px;
    width: 14px;
    // background-color: #fafafa;
    border: 1px solid #cccccc;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}

.custom-tabs {
  > .tab-container {
    > .nav-tabs {
      border-bottom: 1px solid #e6e6e6;

      > .nav-item {
        margin-bottom: 0;

        > .nav-link {
          background: transparent;
          border: 0;
          color: #b3b3b3;
          font-family: $robotoRegular;
          font-size: 15px;
          min-width: 110px;
          padding: 7px;
          display: flex;
          align-items: center;
          justify-content: center;

          .badge {
            background: #b3b3b3;
            font-family: $robotoRegular;
            font-size: 10px;
            margin-left: 5px;
            padding: 2px 4px;
          }

          &.active {
            font-family: $robotoMedium;
            color: #5abcb3;
            border-bottom: 2px solid #5abcb3;

            .badge {
              background: #5abcb3;
            }
          }
        }
      }
    }

    > .tab-content {
      padding-top: 20px;
    }
  }
}
@font-face {
  font-family: 'helveticaregular';
  font-display: 'swap';
  src: url('/assets/fonts/helvetica/helvetica-webfont.woff2') format('woff2'),
    url('/assets/fonts/helvetica/helvetica-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica';
  font-display: 'swap';
  src: url('/assets/fonts/helvetica/helvetica-bold-webfont.woff2')
      format('woff2'),
    url('/assets/fonts/helvetica/helvetica-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helveticalight';
  font-display: 'swap';
  src: url('/assets/fonts/helvetica/helvetica-light-webfont.woff2')
      format('woff2'),
    url('/assets/fonts/helvetica/helvetica-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'questrial-regular';
  src: url('/assets/fonts/questrial/questrial-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-regular';
  src: url('/assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-bold';
  src: url('/assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-medium';
  src: url('/assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
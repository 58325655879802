@import '~assets/sass/variables';

$tableBackground: #fdfdfd;

.quote-table {
  font-family: $robotoRegular;
  font-weight: normal;
  background-color: $tableBackground;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 5px 10px;
  padding-bottom: 15px;

  &.no-pointer {
    table {
      > tbody {
        > tr {
          > td {
            cursor: initial;
          }
        }
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  
    > thead {
      border-bottom: 1px solid #f0f0f0;
  
      > th {
        font-size: 11.1px;
        font-weight: normal;
        color: #999999;
        padding: 10px;
        padding-top: 15px;
  
        &:first-child {
          padding-left: 20px;
        }
        &:last-child {
          padding-right: 20px;
        }
      }
    }
  
    > tbody {
      &:before {
        /* This doesn't work because of border-collapse */
        line-height: 0.5em;
        content: ".";
        color: $tableBackground; /* bacground color */
        display: block;
      }

      > tr {
        &:hover {
          > td {
            background-color: #f5f5f5;
          }
        }
        
        > td {
          cursor: pointer;
          font-size: 12px;
          color: #4d4d4d;
          padding: 5px 10px;
  
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
          }

          .name {
            display: flex;

            > .user-img {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: #4d3b87;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 7px;
              
              > span {
                font-size: 11px;
                color: #ffffff;
                position: relative;
                top: 1px;
              }
            }

            > p {
              margin: 0;
            }
          }

          .account-type {
            > .fas {
              font-size: 16px;
              position: relative;
              top: 2px;
              margin-right: 5px;
              color: #cccccc;

              &.gold {
                color: #debd00;
              }
            }
          }
        }
      }
    }
  }
}

@import '~assets/sass/variables';

.engine-accordion {
  > .card {
    margin-bottom: 10px;
    border: 1px solid #e6e6e6 !important;
    border-radius: 8px !important;
    box-shadow: none;

    > .card-header {
      position: relative;
      padding: 2px 20px;
      background: #fff;

      .color-header {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 7px;
      }

      .acc-header {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        border: 0;
        background: #fff;

        > .result-img {
          flex-grow: 1;
          display: flex;

          > img {
            height: 50px;
          }
        }
        
        > .options {
          display: flex;
          align-items: center;

          > .more-info {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 13px;

            > p {
              font-family: $robotoRegular;
              font-weight: bold;
              font-size: 14px;
              color: #333333;
              margin: 0;
              line-height: 16px;

              > .fas {
                font-size: 12px;
                color: #aa515e;
                margin-right: 5px;
              }
            }

            > span {
              font-family: $robotoRegular;
              font-size: 12px;
              font-style: italic;
              color: #333333;
              margin: 0;
              line-height: 12px;
            }
          }

          > .toggle-btn {
            width: 20px;
            color: #838383;
            border: 0;
            background: transparent;
    
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }

    .card-body {
      padding: 0;

      > .client-detail {
        padding: 10px 20px;
        // padding-right: 60px;
        padding-right: 30px;
        border-bottom: 1px dashed #e6e6e6;

        &:last-child {
          border-bottom: 0;
        }

        > label {
          font-family: $robotoRegular;
          font-weight: bold;
          font-size: 14px;
          color: #666666;
          margin: 0;
        }

        > .benefit {
          > .info {
            display: flex;
  
            > p {
              flex: 1;
              position: relative;
              font-family: $robotoRegular;
              font-size: 14px;
              color: #666666;
              margin: 0;
              padding-left: 15px;
  
              > .fas {
                font-size: 6px;
                position: absolute;
                top: 7px;
                left: 4px;
              }
            }
            
            > span {
              font-family: $robotoRegular;
              font-size: 14px;
              color: #666666;
              margin: 0;
            }

            > .extra-info {
              width: 30px;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              > .info-icn {
                color: #9a9b9c;
                font-size: 12px;
              }
            }
          }

          > .benefit-error {
            position: relative;
            background: #f2f2f2;
            padding: 6px 10px;
            border-radius: 5px;
            display: flex;
            align-items: center;

            > .fas {
              color: #aa515e;
              margin-right: 5px;
              font-size: 12px;
            }

            > span {
              position: relative;
              top: 1px;
              color: #aa515e;
              font-family: $robotoRegular;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }

      > .other-info {
        padding: 10px 20px;
        padding-right: 60px;
        border-top: 1px dashed #e6e6e6;

        > .info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          > label {
            font-family: $robotoRegular;
            font-weight: bold;
            font-size: 14px;
            color: #666666;
            margin: 0;
          }

          > span {
            font-family: $robotoRegular;
            font-weight: bold;
            font-size: 14px;
            color: #666666;
            margin: 0;
          }
        }
      }
    }
  }
}

.cleint-accordion {
  > .card {
    margin-bottom: 10px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background: none;


    > .card-header {
      position: relative;
      padding: 2px 10px;
      background: none;

      .acc-header {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        border: 0;
        background: none;
        padding: 0;

        > .toggle-btn {
          width: 20px;
          text-align: left;

          > .fas {
            font-size: 11px;
            color: #707274;
            position: relative;
            top: -2px;
          }
        }

        > p {
          margin: 0;
          font-family: $robotoRegular;
          font-size: 14px;
          color: #666666;
        }
      }
    }

    .card-body {
      padding: 20px 10px;
    }
  }
}
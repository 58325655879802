@import '~assets/sass/variables';


.quote-engine-ui {
  position: relative;

  > .engine-content {

    > .tool-sidebar {
      position: relative;
      width: 250px;

      > .sidebar-header {
        padding: 10px 30px;
        padding-top: 15px;
        display: flex;
        align-items: center;

        > div {
          flex: 1;

          // &:first-child {
          //   padding-right: 5px;
          // }
          // &:last-child {
          //   padding-left: 5px;
          // }
        }

        .settings-btn {
          background-color: #e6e6e6;
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          height: 30px;
          width: 100%;
          font-family: $robotoRegular;
          color: #666666;
          font-size: 12px;

          > .fas {
            margin-right: 2px;
            font-size: 13px;
          }
        }
        
        ngx-select {
          > .ngx-select {
            > .ngx-select__selected > .ngx-select__toggle {
              height: 30px;
              border: 1px solid #999999;
              color: #666666;
              border-radius: 3px;
              font-family: $robotoRegular;
              font-size: 12px;
      
              > .ngx-select__toggle-buttons {
                align-items: unset;
              }
            }
      
            > .ngx-select__choices > .ngx-select__item-group {
              > .ngx-select__item {
                font-family: $robotoRegular;
                font-size: 12px;
                padding: 3px 15px;
              }
            }
          }
        }
      }

      > .client-list {
        max-height: calc( 100vh - 115px );
        overflow-x: auto;

        &::-webkit-scrollbar-track {
          background-color: $themeMainColor;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: $themeMainColor;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #588f8a;
        }

        > .client-item {
          &.disabled {
            > .client-benefits {
              > .empty-btn:hover {
                color: #80d2ca;
                border: 1px dashed #80d2ca;
              }
            }
          }

          > .details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 30px;

            > .info {
              cursor: pointer;
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              text-decoration: none;

              &.inactive {
                > label {
                  text-decoration: line-through;
                }
              }

              > label {
                cursor: pointer;
                font-family: $robotoRegular;
                font-size: 14px;
                color: #4d4d4d;
                margin: 0;
                line-height: 14px;

                > .icn {
                  margin-left: 5px;
                  position: relative;
                  top: 1px;
                }
              }

              > p {
                font-family: $robotoRegular;
                font-size: 10px;
                line-height: 14px;
                color: #808080;
                opacity: 0.85;
                margin: 0;
                display: flex;
                align-items: center;

                > .fas {
                  margin: 0 4px;
                  font-size: 4px;
                }
              }
            }

            > .btn-option {
              position: relative;

              > button {
                padding: 0;
                padding-right: 2px;
                padding-left: 5px;

                &:focus {
                  box-shadow: none;
                }

                &:after {
                  display: none;
                }

                > .fas {
                  color: #808080;
                  font-size: 14px;
                }
              }

              > .dropdown-menu {
                padding: 5px 0;

                > li > .dropdown-item {
                  cursor: pointer;
                  padding: 5px 20px;
                  display: flex;
                  align-items: center;

                  &:hover {
                    background: #e9f5f4;
                  }

                  &.delete {
                    > .far,
                    > span {
                      color: #ab5462;
                    }
                  }

                  > .far,
                  > .fas {
                    margin-right: 8px;
                    font-size: 13px;
                    color: #666666;
                    position: relative;
                    top: -1px;
                  }

                  > .fas {
                    color: #868686;
                  }

                  > span {
                    cursor: pointer;
                    font-size: 13px;
                  }
                }
              }
            }

            > .inactive-btn {
              cursor: pointer;
              color: #7d7f81;
              font-size: 14px;
            }
          }

          > .client-benefits {
            padding: 0 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #e6e6e6;

            &:hover {
              > .empty-btn.hidden-me {
                display: flex;
              }
            } // #

            > .benefit-item {
              // padding: 3px 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              &:hover {
                > .far {
                  display: block;
                }
              }

              &.inactive {
                &:hover {
                  > .far {
                    display: none;
                  }
                }

                > a {
                  text-decoration: line-through;
                }
              }

              > a {
                padding: 3px 0;
                padding-left: 10px;
                cursor: pointer;
                flex-grow: 1;
                border-radius: 4px;
                background: rgba(#DDEEEC, 0.6);
                color: #666666;
                font-family: $robotoRegular;
                font-size: 12px;
                margin: 0;
                display: flex;
                text-decoration: none;

                &.space-bottom {
                  margin-bottom: 5px;
                }

                > .benefit-icn {
                  height: 15px;
                  margin-right: 5px;
                }

                > .icn {
                  margin-left: 5px;
                  position: relative;
                  top: 1px;
                }
              }

              > .del-btn {
                cursor: pointer;
                font-size: 12px;
                color: #2a2c2f;
                display: none;
                margin-right: 1px;
                margin-left: 5px;
              }
            }

            > .empty-btn {
              cursor: pointer;
              padding: 4px 10px;
              font-family: $robotoBold;
              font-size: 10px;
              color: #7abeb7;
              border: 1px dashed #7abeb7;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 5px;

              &.hidden-me {
                display: none;
              }

              > .fas {
                font-size: 9px;
                margin-right: 5px;
              }

              &:hover {
                color: #72bab3;
                border: 1px dashed #72bab3;
                text-decoration: unset;
              }
            }
          }
        }

        > .sidebar-btn {
          padding: 20px 30px;

          > a {
            display: block;
            cursor: pointer;
            font-family: $robotoMedium;
            font-size: 12px;
            line-height: 14px;        
            background: rgba(#DDD8E6, 0.60);
            border: 1px solid rgba(#DDD8E6, 0.60);
            border-radius: 4px;
            color: #5b4886;
            width: 100%;
            padding: 5px 10px;
            text-align: left;
    
            &.disabled {
              cursor: not-allowed;
            }
    
            > .fas {
              margin-right: 5px;
              font-size: 14px;
            }
    
            &:hover {
              // color: #ffffff;
              text-decoration: unset;
            }
          }
        }
      }
    }
  }
}
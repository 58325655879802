/* You can add global styles to this file, and also import other style files */

/*
Admin LTE customization
*/


// bootstrap functions customization
@import "./admin-lte/bootstrap-functions";

// bootstrap variables customization
@import "./admin-lte/bootstrap-variables";

//build boostrap library
@import '~bootstrap/scss/bootstrap';

@import "./admin-lte/variables";

// build AdminLTE theme styles
@import "~admin-lte/build/scss/adminlte";

// Google Font: Source Sans Pro
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');


@import './variables';

// Quote Tool Engine Styles
@import './quote-tool/main-style';
@import './quote-tool/sidebar';
@import './quote-tool/modal';

// Eagle Quote Admin Styles
@import './eagle-quote/main-style';

// Components
@import './components/forms';
@import './components/benefits';
@import './components/accordion';
@import './components/datepicker';
@import './components/table';
@import './components/pagination';
@import './components/ngx-select-style';
@import './components/tooltip';
@import './components/switches';
@import './components/toast';
@import './components/custom-tabs';
@import './components/custom-dropdown';

body {
  margin: 0;
  font-family: $robotoRegular;
  overflow: hidden;
}

// Admin-LTE style custom

.main-sidebar {
  position: fixed;
  background-color: #1f2a38;
  display: flex;
  flex-direction: column;

  > .brand-link {
    height: $headerNavHeight;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid #7c8795;
    border-bottom: 1px solid #141D29;

    &.bottom-info {
      > .brand-image {
        color: #a6aab0;
        font-size: 30px;
        margin-left: 1rem;
      }

      > .brand-text {
        flex: 1;
        padding-left: 15px;

        > p {
          font-family: $robotoRegular;
          color: #d2d4d7;
          margin: 0;

          &.name {
            font-size: 16px;
            line-height: 16px;
          }
          &.email {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      > .btn-option {
        position: relative;
  
        > .btn {
          color: #c7c9cb;
          border-radius: 50%;
          padding: 2px 11px;

          &:hover,
          &:focus {
            background: #39414d;
          }

          > .fas {
            position: relative;
            top: 1px;
          }
        }
      }
    }
  
    > .brand-image {
      height: 25px;
      max-height: unset;
      margin-top: 0;
    }
    > .brand-text > img {
      height: 25px;
      position: relative;
      left: -36px;
    }
  }

  > .sidebar {
    padding: 0;
    overflow-y: unset !important;
    flex: 1;

    .nav {
      > .nav-item {
        > .nav-link {
          width: 100%;
          border-radius: 0;
          padding: 1rem 1.2rem;
          color: #c5c7ca;
          border-left: 3px solid #1f2a38;

          &:hover {
            color: #ffffff;
          }

          &.active {
            background-color: #161d28;
            color: #ffffff;
            border-left: 3px solid #72bab3;
          }

          > .nav-icon {
            position: relative;
            height: 22px;
            width: 22px;
            margin-right: 12px;
            margin-left: 2px;
          }

          > p {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.account-dropdown {
  padding: 5px 0;
  left: -8px !important;
  right: auto !important;
  bottom: -8px !important;
  transform: none !important;

  > li {
    &.disabled {
      opacity: 0.4;

      > .dropdown-item {
        cursor: not-allowed;

        > span {
          cursor: not-allowed;
        }
      }
    }

    > .dropdown-item {
      cursor: pointer;
      padding: 5px 20px;
      display: flex;
      align-items: center;

      &:hover {
        background: #e9f5f4;
      }

      &.delete {
        > .far,
        > span {
          color: #ab5462;
        }
      }

      &.json {
        > .fas {
          font-size: 11px;
          margin-right: 6px;
          position: relative;
          right: 1px;
        }
        > span {
          font-size: 12px;
        }
      }

      > .far,
      > .fas {
        margin-right: 10px;
        font-size: 13px;
        color: #666666;
      }

      > .fas {
        color: #868686;
        position: relative;
        top: -1px;
      }

      > span {
        cursor: pointer;
        font-size: 13px;
      }
    }
  }
}
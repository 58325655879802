/* 
  Default Style Settings
  Use this code to import to a component:
  @import '~assets/sass/variables';
*/

@import './typography';

$helveticaRegular: 'helveticaregular', Arial, 'Lucida Grande', sans-serif;
$helveticaBold: 'helvetica', Arial, 'Lucida Grande', sans-serif;
$helveticaLight: 'helveticalight', Arial, 'Lucida Grande', sans-serif;
$questrialRegular: 'questrial-regular', 'Helvetica', Arial, 'Lucida Grande', sans-serif;
$robotoRegular: 'roboto-regular', 'Helvetica', Arial, 'Lucida Grande', sans-serif;
$robotoBold: 'roboto-bold', 'Helvetica', Arial, 'Lucida Grande', sans-serif;
$robotoMedium: 'roboto-medium', 'Helvetica', Arial, 'Lucida Grande', sans-serif;

$themeMainColor: #72bab3;

$headerNavHeight: 60px;

@import '~assets/sass/variables';

section {
  position: relative;
}

.benefit-container {
  padding: 0 30px;

  &.inactive {

    .benefit-header {
      cursor: pointer;
      background: #b3b3b3;

      &:hover {
        background: #71bab3;

        > label {
          text-decoration: unset;
        }

        > .options {
          > .activate-btn {
            display: block;
          }
          > .inactive-btn {
            display: none;
          }
        }
      }
      
      > label {
        text-decoration: line-through;
      }

      > .options {
        > .activate-btn {
          display: none;
        }
        > .inactive-btn {
          display: block;
        }
      }
    }

    &-full {
      .benefit-header {
        cursor: default;
        background: #b3b3b3;
      
        &:hover {
          background: #b3b3b3;

          > label {
            text-decoration: line-through;
          }
        }
      
        > label {
          text-decoration: line-through;
        }
      }
    }
  }

  &.disabled {
    * {
      cursor: unset !important;
      opacity: 0.85;
    }

    > .benefit-header {
      opacity: 0.7;
    }
  }

  > .benefit-header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #42a199;
    padding: 5px 20px;
    border-radius: 3px;

    &.opened {
      background: #5d5d5d;

      > .options {
        > .opened-icn {
          cursor: pointer;
          color: #ffffff;
          margin-right: 5px;
        }

        > .product-btn {
          background: #e6e6e6;
          border: 1px solid #e6e6e6;
          color: #5d5d5d;

          > .fas {
            color: #5d5d5d;
          }

          > .badge {
            background: #5d5d5d;
            color: #e6e6e6;
          }

          > label {
            font-weight: bold;
            color: #5d5d5d;
          }
        }
      }
    }

    > label {
      font-family: $robotoMedium;
      font-weight: normal;
      font-size: 14px;
      color: #ffffff;
      margin: 0;
      display: flex;
      align-items: center;

      > .benefit-icn {
        height: 15px;
        margin-right: 7px;
      }
    }

    > .options {
      display: flex;
      align-items: center;

      > .activate-btn {
        border: 0;
        background: transparent;
        font-size: 12px;
        color: #fff;

        > .fas {
          margin-left: 5px;
          font-size: 14px;
        }
      }

      > .inactive-btn {
        border: 0;
        background: transparent;
        font-size: 12px;
        color: #e1e1e1;

        > .fas {
          color: #dedede;
          margin-left: 5px;
          font-size: 14px;
        }
      }

      > .product-btn {
        cursor: pointer;
        border: 1px solid #ffffff;
        border-radius: 5px;
        padding: 0 5px;
        height: 23px;
        display: flex;
        align-items: center;

        &.red-fill {
          background: #a54854;

          > .badge {
            color: #a54854;
          }
        }

        > .fas {
          cursor: pointer;
          font-size: 12px;
          color: #ffffff;
          margin-right: 5px;
        }

        > label {
          cursor: pointer;
          font-family: $robotoRegular;
          font-weight: normal;
          font-size: 12px;
          color: #ffffff;
          margin: 0;
          line-height: 18px;
        }

        > .badge {
          cursor: pointer;
          background: #ffffff;
          margin-left: 5px;
          color: #42a199;
          font-family: $robotoRegular;
          font-weight: bold;
          font-size: 12px;
          padding: 0.1em 0.3em;
          border-radius: 3px;  
        }
      }

      > .del-btn {
        cursor: pointer;
        font-size: 14px;
        color: #ffffff;
        margin-left: 5px;
      }

      > .btn-option {
        position: relative;

        > button {
          padding-top: 0;
          padding-bottom: 0;
          padding-right: 0;

          &:focus {
            box-shadow: none;
          }

          &:after {
            display: none;
          }

          > .fas {
            color: #ffffff;
            font-size: 14px;
          }
        }

        > .dropdown-menu {
          padding: 5px 0;

          > li > .dropdown-item {
            cursor: pointer;
            padding: 5px 20px;
            display: flex;
            align-items: center;

            &:hover {
              background: #e9f5f4;
            }

            &.delete {
              > .far,
              > span {
                color: #ab5462;
              }
            }

            > .far,
            > .fas {
              margin-right: 8px;
              font-size: 13px;
              color: #666666;
              position: relative;
              top: -1px;
            }

            > .fas {
              color: #868686;
            }

            > span {
              cursor: pointer;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  > .benefit-body {
    background: #f2f2f2;
    padding: 10px 20px;
    padding-bottom: 15px;
  }
}

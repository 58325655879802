@import '~assets/sass/variables';

.quote-engine-ui {
  position: relative;
  background-color: #F8FAFA;

  .invisible-box {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }

  > .navbar {
    height: $headerNavHeight;
    border-bottom: 1px solid #e6e6e6;
  }

  .engine-header {
    height: $headerNavHeight;
    background-color: #FDFDFD;
    padding: 10px 20px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.align-right {
      justify-content: flex-end;
    }

    > .quote-name {
      flex: 1;
      padding-right: 15px;

      &:hover {
        > .quote-field {
          border-color: #e6e6e6;
        }
      }

      > h3 {
        font-family: $robotoRegular;
        font-weight: bold;
        font-size: 16px;
        color: #4d4d4d;
        margin: 0;
      }

      > .quote-field {
        width: 100%;
        font-family: $robotoRegular;
        font-weight: bold;
        font-size: 16px;
        color: #4d4d4d;
        position: relative;
        border: 1px solid transparent;
        border-radius: 4px;
        left: -5px;
        padding: 0 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    > .back-btn {
      background-color: #ffffff;
      color: #999999;
      border: 0;
      font-size: 14px;
      font-family: $robotoRegular;
      line-height: 18px;  

      > .fas {
        font-size: 12px;
        margin-right: 3px;
      }
    }

    > .btn-option {
      position: relative;

      > .btn {
        padding: 0;
        color: #666666;
        background: #e6e6e6;
        border: 1px solid #b3b3b3;
        font-family: $robotoRegular;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;

        &:focus {
          box-shadow: none;
        }

        &:after {
          display: none;
        }

        > .lbl {
          display: flex;
          align-items: center;
          padding: 3px 10px;
          padding-right: 3px;

          > .fas {
            color: #666666;
            font-size: 12px;
            margin-right: 5px;
          }
        }

        > .icn-down {
          border-left: 1px solid #b3b3b3;
          padding: 3px 10px;
          padding-left: 7px;
          margin-left: 5px;

          > .fas {
            color: #666666;
            margin-right: 0;
            font-size: 10px;
          }
        }
      }

      > .dropdown-menu {
        padding: 5px 0;

        > li {
          &.disabled {
            opacity: 0.4;

            > .dropdown-item {
              cursor: not-allowed;

              > span {
                cursor: not-allowed;
              }
            }
          }

          > .dropdown-item {
            cursor: pointer;
            padding: 5px 20px;
            display: flex;
            align-items: center;

            &:hover {
              background: #e9f5f4;
            }

            &.delete {
              > .far,
              > span {
                color: #ab5462;
              }
            }

            &.json {
              > .fas {
                font-size: 11px;
                margin-right: 6px;
                position: relative;
                right: 1px;
              }
              > span {
                font-size: 12px;
              }
            }

            > .far,
            > .fas {
              margin-right: 10px;
              font-size: 13px;
              color: #666666;
            }

            > .fas {
              color: #868686;
              position: relative;
              top: -1px;
            }

            > span {
              cursor: pointer;
              font-size: 13px;
            }
          }
        }
      }
    }

    .result-btn-container {
      position: relative;
      display: flex;

      > a {
        padding: 3px 15px;
      }

      > button {
        padding: 3px 15px;
      }

      .result-btn {
        cursor: pointer;
        background: #5abcb3;
        border: 1px solid #5abcb3;
        border-radius: 4px;
        color: #ffffff;
        font-family: $robotoMedium;
        font-weight: normal;
        font-size: 12px;
        text-decoration: none;

        > .fas {
          position: relative;
          top: 1px;
          font-size: 12px;
          margin-right: 5px;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

  }

  > .engine-content {
    height: 100vh;
    display: flex;

    .calculating-block {
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      background: rgba(#000000, 0.2);
    }

    > .main-content {
      position: relative;
      flex: 1;
      z-index: 999;

      @media (min-width: 1500px) {
        max-width: 600px;
      }

      @media (max-width: 1499px) {
        min-width: 500px;
      }

      &.calculating {
        overflow-x: unset;
      }

      .engine-header {
        padding: 10px 30px;
      }

      .invisible-box {
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
      }

      .client-wrapper {
        height: calc( 100vh - 60px );
        overflow-x: auto;
        scroll-behavior: smooth;

        .client-container {
          > .benefit-options-container {
            background: #f2f2f2;
            margin: 0 30px;
            margin-bottom: 10px;
            padding-top: 10px;
  
            .add-benefit-btn {
              cursor: pointer;
              padding: 8px 10px;
              border-radius: 3px;
              border: 1px dashed #999999;
              font-family: $robotoBold;
              font-size: 14px;
              color: #999999;
              line-height: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-decoration: unset;
      
              > .fas {
                font-size: 12px;
                margin-right: 5px;
              }
            }
      
            .benefit-options {
              position: relative;
              border: 1px solid #999999;
              border-radius: 10px;
              padding: 15px;
      
              > .btn-close {
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                color: #666666;
              }
              
              > h3 {
                font-family: $robotoBold;
                font-size: 14px;
                color: #666666;
                margin: 0;
                margin-bottom: 10px;
              }
      
              > .benefit-items {
                > .item {
                  cursor: pointer;
                  font-family: $robotoRegular;
                  font-size: 13px;
                  line-height: 18px;
                  color: #666666;
                  margin: 0;
                  margin-bottom: 10px;
                  display: flex;
                  text-decoration: none;
      
                  &:hover {
                    color: #588f8a;
                    font-weight: bold;
    
                    > .benefit-icn {
                      &.green {
                        display: block;
                      }
                      &.grey {
                        display: none;
                      }
                    }
                  }
      
                  &:last-child {
                    margin-bottom: 0;
                  }
    
                  > .benefit-icn {
                    height: 14px;
                    margin-right: 10px;
    
                    &.green {
                      display: none;
                    }
                    &.grey {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    > .other-content {
      flex: 1;
      position: relative;
      overflow-x: unset;
      z-index: 999;

      .blocker-div {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;

        &.results {
          background: rgba(#000000, 0.2);
        }
      }

      .engine-header {
        padding-right: 60px;
      }

      .result-list-btn {
        cursor: pointer;
        font-size: 12px;
        background-color: transparent;
        font-family: $robotoMedium;
        font-weight: normal;
        color: #5abcb3;
        border: 1px solid #5abcb3;
        padding: 3px 15px;
        border-radius: 4px;
        margin-right: 10px;

        > .fas {
          margin-right: 3px;
        }
      }

      .products-container {
        position: absolute;
        top: 0;
        right: 0;
        width: 600px;
        height: 100%;
        background: #666666;
        padding: 20px 40px;
        z-index: 99;
        overflow: auto;

        @media (max-width: 1479px) {
          width: 100%;
        }

        > .products-header {
          margin-bottom: 20px;

          > .title {
            position: relative;
            margin-bottom: 15px;

            > label {
              font-family: $robotoRegular;
              font-weight: normal;
              font-size: 18px;
              color: #fafafa;
              display: flex;
              align-items: center;

              > .fas {
                cursor: pointer;
                margin-right: 10px;
                font-size: 16px;
              }
            }

            > .close-btn {
              cursor: pointer;
              position: absolute;
              top: 5px;
              right: 5px;
              font-size: 16px;
              color: #fafafa;
            }
          }

          > .select-all {
            padding: 10px 15px;
            padding-bottom: 15px;
            border-bottom: 2px solid #ababab;
          }
        }

        > .products-body {
          > .product-item {
            margin-bottom: 10px;
            padding: 0 15px;

            &.has-group {
              background: #5d5d5d;
              border-radius: 10px;
              padding: 15px;
            }

            > .product-list {
              padding-left: 20px;
              margin-top: 5px;
            }
          }
        }
      }
      
      .result-container {
        position: fixed;
        top: 0;
        right: 0;
        width: 600px;
        height: 100%;
        background-color: #F8FAFA;
        display: block;

        @media (max-width: 1479px) {
          &.hide-container {
            display: none;
          }
        }

        > .engine-header {
          justify-content: space-between;
          padding-left: 40px;
          padding-right: 100px;

          .lbl-header {
            position: relative;

            > label {
              font-family: $robotoMedium;
              font-weight: normal;
              font-size: 16px;
              line-height: 20px;
              color: #4d4d4d;
              display: flex;
              align-items: center;
              margin: 0;

              > .fas {
                cursor: pointer;
                margin-right: 10px;
                font-size: 14px;
              }
            }
          }
        }

        > .result-body {
          // height: calc( 100vh - 131px );
          height: calc( 100vh - 60px );
          overflow-x: auto;
          padding: 15px 40px;
          padding-right: 100px;

          > .changes-required {
            background-color: #f1ebec;
            font-family: $robotoMedium;
            font-weight: normal;
            font-size: 12px;
            color: #b05f65;
            padding: 5px 10px;
            border-radius: 5px;
            margin-bottom: 15px;

            > .fas {
              margin-right: 5px;
            }
          }

          > .result-quote-id {
            display: flex;
            justify-content: flex-end;
            padding-right: 10px;

            > label {
              font-family: $robotoRegular;
              font-weight: normal;
              font-size: 10px;
              color: #999999;
              margin: 0;
            }
          }

          > .result-content {
            margin-bottom: 30px;

            > .result-section-header {
              position: relative;
              margin-bottom: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              > label {
                font-family: $robotoRegular;
                font-weight: normal;
                font-size: 14px;
                color: #333333;
                margin: 0;
              }

              > .option-btn {
                font-family: $robotoMedium;
                font-weight: normal;
                cursor: pointer;
                color: #6ac2ba;
                background-color: #ddeeec;
                border-radius: 4px;
                padding: 2px 10px;
                text-decoration: none;
                font-family: $robotoRegular;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
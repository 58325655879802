@import '~assets/sass/variables';

.eagle-quote-wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  background-color: #F8FAFA;

  .engine-header {
    height: $headerNavHeight;
    background-color: #FDFDFD;
    padding: 10px 20px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;

    &.sub-page {
      padding-left: 30px;
      padding-right: 50px;

      display: flex;
      justify-content: space-between;

      > .action-section {
        > .add-btn,
        > .export-btn {
          min-width: 90px;
        }

        > .add-btn {
          cursor: pointer;
          font-size: 12px;
          font-family: $robotoMedium;
          font-weight: normal;
          color: #ffffff;
          background-color: #5abcb3;
          border: 1px solid #5abcb3;
          padding: 3px 10px;
          border-radius: 5px;

          > .fas {
            margin-right: 5px;
            font-size: 10px;
          }

          &:hover {
            box-shadow: 1px 1px 6px #dbdbdb;
          }
        }

        > .export-btn {
          cursor: pointer;
          font-size: 12px;
          font-family: $robotoMedium;
          font-weight: normal;
          color: #686868;
          background-color: #ddeeec;
          border: 1px solid #ddeeec;
          padding: 3px 10px;
          border-radius: 5px;
          margin-right: 10px;

          > .fas {
            margin-right: 5px;
            font-size: 10px;
          }

          &:hover {
            background-color: #ccede9;
            border: 1px solid #ccede9;
            box-shadow: 1px 1px 6px #dbdbdb;
          }

          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;

            &:hover {
              background-color: #ddeeec;
              border: 1px solid #ddeeec;
              box-shadow: unset;
            }
          }
        }
      }
    }

    &.align-right {
      justify-content: flex-end;
    }

    > .title {
      font-family: $robotoMedium;
      font-weight: normal;
      font-size: 16px;
      color: #666666;
      margin: 0;
    }

    > h3 {
      font-family: $robotoRegular;
      font-weight: bold;
      font-size: 16px;
      color: #4d4d4d;
      margin: 0;
    }

    > .back-btn {
      background-color: #ffffff;
      color: #999999;
      border: 0;
      font-size: 14px;
      font-family: $robotoRegular;
      line-height: 18px;  

      > .fas {
        font-size: 12px;
        margin-right: 3px;
      }
    }

    .result-btn-container {
      position: relative;
      display: flex;

      > a {
        padding: 3px 15px;
      }

      > button {
        padding: 3px 15px;
      }

      .result-btn {
        cursor: pointer;
        background: #5abcb3;
        border: 1px solid #5abcb3;
        border-radius: 4px;
        color: #ffffff;
        font-family: $robotoMedium;
        font-weight: normal;
        font-size: 12px;
        text-decoration: none;

        > .fas {
          position: relative;
          top: 1px;
          font-size: 12px;
          margin-right: 5px;
        }

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    }

  }

  > .eagle-quote-sidebar {
    position: relative;
    width: 250px;

    > .menu-list {
      padding: 20px;

      > .menu-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 7px 12px;
        padding-top: 8px;
        border-radius: 5px;
        color: #666666;
        margin-bottom: 5px;

        &.active {
          background-color: #e6e6e6;
          color: #4d4d4d;

          > span {
            font-family: $robotoMedium;
            font-weight: normal;
          }
        }

        &:hover {
          background-color: #e6e6e6;
        }
        
        > .fas,
        > .far {
          font-size: 12px;
          margin-right: 10px;

          &.lg-icon {
            font-size: 13px;
            margin-right: 13px;
          }
        }

        > .far {
          margin-right: 13px;
        }

        > span {
          font-family: $robotoRegular;
          font-weight: normal;
          font-size: 12px;
        }
      }
    }
  }

  > .eagle-quote-container {
    flex: 1;
  }
}
$feildHeight: 26px;
$inputErrorColor: #a54855;
$inputErrorField: #a54855;
$borderColor: #999999;

.custom-dropdown {
  > .ngx-select {
    width: 130px;

    > .ngx-select__selected > .ngx-select__toggle {
      height: $feildHeight;
      // background: #ffffff;
      // border: 1px solid $borderColor;
      // border-radius: 3px;
      color: #666666;
      font-family: $robotoRegular;
      font-size: 12px;
      background: transparent;
      border: 1px solid #e6e6e6;
      border-radius: 8px;

      > .ngx-select__toggle-buttons {
        align-items: unset;
      }

      > .ngx-select__selected-single {
        > .status-color {
          margin-right: 7px;

          &.new {
            &::before{
              background-color: transparent;
              border: 1px solid #cccccc;
            }
          }

          &.complete {
            &::before{
              background-color: #85BE7E;
              border: 1px solid #85BE7E;
            }
          }

          &.in-progress {
            &::before{
              background-color: #F9CA5C;
              border: 1px solid #F9CA5C;
            }
          }

          &.cancelled {
            &::before{
              background-color: #B64B5D;
              border: 1px solid #B64B5D;
            }
          }

          &::before{
            content: '';
            display: inline-block;
            width: 9px;
            height: 9px;
            -moz-border-radius: 7.5px;
            -webkit-border-radius: 7.5px;
            border-radius: 7.5px;
            background-color: transparent;
            border: 1px solid #cccccc;
            position: relative;
            top: 1px;
            
         }
        }
      }
    }

    > .ngx-select__choices > .ngx-select__item-group {
      > .ngx-select__item {
        font-family: $robotoRegular;
        font-size: 12px;
        padding: 3px 10px;
      }
    }

    > .custom-dropdown-menu {
      left: unset;
      right: 0;
      min-width: unset;

      > .ngx-select__item-group {
        &:first-child{
          > .ngx-select__item_active {
            color: inherit;
            background-color: transparent;
          }
        }

        > .dropdown-item {
          .label-status {
            font-size: 11px;
            color: #9a9a9a;
          }
          
          .status-color {
            margin-right: 7px;
  
            &.new {
              &::before{
                background-color: transparent;
                border: 1px solid #cccccc;
              }
            }
  
            &.complete {
              &::before{
                background-color: #85BE7E;
                border: 1px solid #85BE7E;
              }
            }
  
            &.in-progress {
              &::before{
                background-color: #F9CA5C;
                border: 1px solid #F9CA5C;
              }
            }
  
            &.cancelled {
              &::before{
                background-color: #B64B5D;
                border: 1px solid #B64B5D;
              }
            }
  
            &::before{
              content: '';
              display: inline-block;
              width: 9px;
              height: 9px;
              -moz-border-radius: 7.5px;
              -webkit-border-radius: 7.5px;
              border-radius: 7.5px;
              background-color: transparent;
              border: 1px solid #cccccc;
              position: relative;
              top: 1px;
              
           }
          }
        }
      }
    }
  }
}
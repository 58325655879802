.engine-qoute-options {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // min-height: 100vh;
  width: 100vw !important;
  max-width: unset;
  margin-top: 100px !important;

  .modal-content {
    position: relative;
    width: 650px !important;
    border: 0 !important;
  }

  @media (max-width: 450px) {
    margin-top: 20px !important;

    >.modal-content {
      width: 100% !important;
    }
  }
}

.engine-qoute-results {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  // min-height: 100vh;
  width: 100vw !important;
  max-width: unset;
  margin: 0;

  .modal-content {
    border-radius: 0;
    height: 100vh;
    width: 650px !important;
  }
}

.eagle-qoute-password {
  margin-top: 100px !important;

  .modal-content {
    position: relative;
    width: 400px !important;
    border: 0 !important;
  }

  @media (max-width: 450px) {
    margin-top: 20px !important;

    >.modal-content {
      width: 100% !important;
    }
  }
}

.quote-confirmation {
  margin-top: 100px !important;
  display: flex;
  justify-content: center;

  .modal-content {
    position: relative;
    width: 400px !important;
    border: 0 !important;
    border-radius: 10px;
  }

  @media (max-width: 450px) {
    margin-top: 20px !important;

    >.modal-content {
      width: 100% !important;
    }
  }
}

.auditlogs-detail {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  max-width: unset;

  .modal-content {
    position: relative;
    width: 500px;
    height: 100vh;
    border: 0 !important;
    border-radius: 0;
  }

  @media (max-width: 550px) {
    >.modal-content {
      width: 100% !important;
    }
  }
}

.eagle-users-export-confirmation {
  margin-top: 100px !important;
  display: flex;
  justify-content: center;

  .modal-content {
    position: relative;
    width: 300px !important;
    border: 0 !important;
    border-radius: 10px;

    p {
      padding: 0 !important;
    }
  }

  @media (max-width: 450px) {
    margin-top: 20px !important;

    >.modal-content {
      width: 100% !important;
    }
  }
}

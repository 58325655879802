.pagination-container {
  padding: 0 10px;
  margin-top: 10px;
  display: inline-flex;
  width: 100%;
  align-items: center;
  .left {
    width: 50%;
    font-size: 12px;
    color: #666666;

    p {
      margin: 0;
    }
  }
  .right {
    width: 50%;
  }
}
$tooltipColor: #1f2a38;

.tooltip-custom {
  opacity: 1 !important;

  > .tooltip-arrow:before {
    border-bottom-color: $tooltipColor;
  }

  > .tooltip-inner {
    background-color: $tooltipColor;
    padding: 6px 15px;

    p {
      margin: 0;
      text-align: left;
      font-size: 12px;
    }
  }
}
.quote-toast {
  position: relative;
  bottom: 5px;
  right: 10px;
  background-color: rgba(#5abcb3, 0.2) !important;
  font-family: $robotoRegular;
  font-size: 12px;
  color: #666666;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.ins-app-toast {
  position: relative;
  top: 60px;
  right: 10px;
  background-color: rgba(#a54855, 0.2) !important;
  font-family: $robotoRegular;
  font-size: 12px;
  color: #666666;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.eagle-app-toast {
  position: relative;
  top: 60px;
  right: 10px;
  background-color: #ccede9 !important;
  font-family: $robotoRegular;
  font-size: 12px;
  color: #686868;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  &.error {
    background-color: #ad868a !important;
    color: #ffffff;
  }
}

.switch-label {
  display: flex;
  justify-content: center;
  align-items: center;

  &.pointer {
    cursor: pointer;
  }

  &.align-left {
    justify-content: flex-start;
  }

  > .custom-switch {
    margin-right: 7px;
  }
}

.custom-switch {
  position: relative;
  cursor: pointer;
  --color: #f6e061;
  padding-left: 0;

  .switch-input {
    display: none;
  }

  .switch-input:checked~.switch-indicator {
    background: #d9bc3f;
  }

  .switch-input:checked~.switch-indicator::after {
    background-color: var(--color);
    border: 1px solid #d9bc3f;
    left: 17px;
  }

  .switch-indicator {
    display: inline-block;
    position: relative;
    margin: 0 2px;
    top: 5px;
    width: 35px;
    height: 10px;
    background: #ddd;
    border-radius: 16px;
    transition: 0.3s;
  }

  .switch-indicator::after {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: 0.3s;
    top: -4px;
    left: -1px;
    background: #fdfdfd;
    border: 1px solid #ddd;
    // box-shadow: 0 2px 10px #aaa;
  }
}
